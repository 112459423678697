// src/components/ProgressBar.js
import React, { useEffect, useState } from 'react';
import './ProgressBar.css';

const ProgressBar = ({ duration, startProgress, completeImmediately }) => {
    const [percentage, setPercentage] = useState(0);

    useEffect(() => {
        let animationFrame;
        if (startProgress && !completeImmediately) {
            const startTime = Date.now();

            const updateProgress = () => {
                const now = Date.now();
                const elapsed = now - startTime;
                const progress = Math.min((elapsed / (duration * 1000 * 20)) * 100, 100);
                setPercentage(progress);

                if (progress < 100) {
                    animationFrame = requestAnimationFrame(updateProgress);
                }
            };

            animationFrame = requestAnimationFrame(updateProgress);
        } else if (startProgress && completeImmediately) {
            setPercentage(100);
        }

        return () => {
            cancelAnimationFrame(animationFrame);
            setPercentage(0);
        };
    }, [startProgress, duration, completeImmediately]);

    return (
        <div
            className="progress-bar-wrapper"
            role="progressbar"
            aria-valuenow={percentage}
            aria-valuemin="0"
            aria-valuemax="100"
        >
            <div className="progress-bar-background">
                <div
                    className="progress-bar-fill"
                    style={{ width: `${percentage}%` }}
                ></div>
            </div>
            <div className="progress-bar-overlay">
                <span className="progress-percentage">
                    {percentage < 100 ? `Translating... ${Math.round(percentage)}%` : 'Complete'}
                </span>
            </div>
        </div>
    );
};

export default ProgressBar;
