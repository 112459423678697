// /src/App.js

import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { auth } from './firebase-config';
import SignIn from './SignIn';
import SignOut from './SignOut';
import Translate from './Translate';
import { listenToBalance } from './components/userBalance';
import './App.css';
import IframeCarousel from './components/IframeCarousel';
import TopUpBalance from './components/topUpBalance';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { getRedirectResult, onAuthStateChanged } from 'firebase/auth';
import './i18n';
import './Motivation.css';

function App() {
  const [user, setUser] = useState(null);
  const [balance, setBalance] = useState(0);
  const { t, i18n } = useTranslation();
  const additionalContentRef = useRef(null);
  const topRef = useRef(null); // New ref for scrolling to top

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    const handleAuthState = async () => {
      try {
        const result = await getRedirectResult(auth);
        if (result) {
          console.log(t('userSignedInRedirect', { defaultValue: "User signed in via redirect." }), result.user);
          setUser(result.user);
          listenToBalance(result.user.uid, setBalance);
        }
      } catch (error) {
        console.error(t('errorRetrievingRedirectResult', { defaultValue: "Error retrieving redirect result." }), error);
      }

      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          console.log(t('userIsSignedIn', { defaultValue: "User is signed in." }), user);
          setUser(user);
          listenToBalance(user.uid, setBalance);
        } else {
          console.log(t('noUserSignedIn', { defaultValue: "No user is signed in." }));
          setUser(null);
          setBalance(0);
        }
      });

      return () => unsubscribe();
    };

    handleAuthState();
  }, [t]);

  const client_id = "AboksERa_Kd4qapxcQehGkoxbvXg1oSgDyfFNa1T3WdWRJHTtTQGLZA3N0UbMoul3jNuDYzuc4RMRUU1";

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector('.navbar');
      if (window.scrollY > 50) {
        navbar.classList.add('scrolled');
      } else {
        navbar.classList.remove('scrolled');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Define the scrollToTop function
  const scrollToTop = () => {
    console.log("Scroll to top triggered"); // Debugging statement
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="App" id="Home" ref={topRef}>
      <nav className="navbar">
        <div className="logo-nav-container">
          {/* Updated Logo Element */}
          <button
            className="logo-play-button"
            onClick={scrollToTop}
            aria-label={t('altLogo', { defaultValue: "Go to Home" })}
          >
            <img src="/images/logo.png" alt={t('altLogo', { defaultValue: "YouTranslate Logo" })} />
          </button>
          {/* Removed YouTube-specific navigation link */}
          <a href="#videos-demo" className="about-link">{t('howTo', { defaultValue: "How to" })}</a>
          <a href="#pricing" className="about-link">{t('navPricing', { defaultValue: "Pricing" })}</a>
          <a href="#about" className="about-link">{t('navAbout', { defaultValue: "About" })}</a>
          <select onChange={(e) => changeLanguage(e.target.value)} defaultValue={i18n.language}>
            <option value="en">English</option>
            <option value="es">Español</option>
            <option value="fr">Français</option>
            <option value="ar">عربي</option>
            <option value="ru">Русский</option>
            <option value="in">Bahasa Indonesia</option>
            <option value="ur">اردو</option>
          </select>
        </div>

        <div>
          {user ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: '20px', marginBottom: '8px' }}>
                <PayPalScriptProvider options={{ "client-id": client_id }}>
                  <TopUpBalance user={user} client_id={client_id} />
                </PayPalScriptProvider>
              </div>

              <span>{t('helloUser', { name: user.displayName, defaultValue: "Hello, {{name}}" })}</span>
              <span style={{ marginLeft: '20px', marginRight: '30px', fontSize: '15px' }}>
                {t('balance', { balance: `$${balance}` })}
              </span>
              <SignOut />
            </div>
          ) : (
            <SignIn />
          )}
        </div>
      </nav>

      <div className="middle-content">
        {!user && (
          <section className="motivation-section">
            <div className="motivation-content">
              <div className="motivation-text">
                {/* Updated Promotional Headline and Subheadline */}
                <h2 className="green-text">
                  {t('promoHeadline', { defaultValue: "Professional Video and Audio Translations Made Easy" })}
                </h2>

                <p>
                  {t('promoSubheadline', { defaultValue: "Deliver your content to a global audience with high-quality, affordable translation services." })}
                </p>
              </div>
              <div className="motivation-image">
                <img src="/images/motivation-image.png" alt={t('motivationImageAlt', { defaultValue: "Motivational Image" })} />
              </div>
            </div>
          </section>
        )}

        <Translate user={user} />
        <div ref={additionalContentRef} className="additional-content">
          <div id="videos-demo">
            <IframeCarousel />
          </div>
          <div className="pricing-container" id="pricing">
            <div className="pricing-card">
              <h2>{t('audioTranslationTitle', { defaultValue: "Audio Translation" })}</h2>
              <p className="price">{t('audioTranslationPrice', { defaultValue: "$0.099 per 1 minute of content" })}</p>
              <ul>
                <li>{t('audioTranslationDesc1', { defaultValue: "Content up to 1 hour" })}</li>
                <li>{t('audioTranslationDesc3', { defaultValue: "High-quality voiceover in target language generated" })}</li>
                <li>{t('audioTranslationDesc4', { defaultValue: "Subtitles generated and formatted in the original language" })}</li>
                <li>{t('audioTranslationDesc5', { defaultValue: "Subtitles generated and formatted in the target language" })}</li>
                <li>{t('audioTranslationDesc7', { defaultValue: "Descr" })}</li>
                {/* <li>{t('audioTranslationDesc6', { defaultValue: "Content title translation" })}</li> */}
              </ul>
            </div>
            <div className="pricing-card">
              <h2>{t('enterpriseSolutionsTitle', { defaultValue: "Enterprise Solutions" })}</h2>
              <p className="price">{t('enterpriseSolutionsPrice', { defaultValue: "Contact for pricing" })}</p>
              <p>{t('enterpriseSolutionsDesc', { defaultValue: "Customized solutions for your business needs" })}</p>
              <a href="mailto:translator@youtranslate.me">
                <button className="contact-button">{t('contactUs', { defaultValue: "Contact Us" })}</button>
              </a>
            </div>
          </div>
          <div className="terms-container" id="about">
            <h2>{t('ourMissionTitle', { defaultValue: "Our Mission" })}</h2>
            <p>{t('ourMissionDesc1', { defaultValue: "Providing high-quality translations at an affordable price." })}</p>
            <p>{t('ourMissionDesc2', { defaultValue: "Ensuring accuracy and reliability in every translation." })}</p>
            <p>{t('ourMissionDesc3', { defaultValue: "Expanding our services to support more languages and regions." })}</p>
            <h2>{t('whyChooseUsTitle', { defaultValue: "Why Choose Us" })}</h2>
            <ul>
              <li>{t('whyChooseUsItem1', { defaultValue: "AI-Driven Excellence: Our advanced AI acts like a super polyglot, delivering fast, accurate translations across multiple languages." })}</li>
              <li>{t('whyChooseUsItem2', { defaultValue: "Affordability: Our platform drastically reduces costs by eliminating the need for a dedicated human translator for each language, offering the unmatched pricing in the market that brings the power of multilingual content to every creator." })}</li>
              <li>{t('whyChooseUsItem3', { defaultValue: "Corporate Partnerships: We are ready to work with corporate clients, adapting to nuanced needs with tailored solutions." })}</li>
              <li>{t('whyChooseUsItem4', { defaultValue: "Fast Delivery: Experience translations much faster than human translators can deliver, ensuring your content keeps pace with demand." })}</li>
              <li>{t('whyChooseUsItem5', { defaultValue: "Ease of Use: Upload your content, select your languages, and our technology does the rest." })}</li>
            </ul>
            <h2>{t('lookingForwardTitle', { defaultValue: "Looking Forward" })}</h2>
            <p>{t('lookingForwardDesc', { defaultValue: "We are constantly innovating to improve our services." })}</p>
            <h2>{t('corporatePartnershipsTitle', { defaultValue: "Corporate Partnerships" })}</h2>
            <p>{t('corporatePartnershipsDesc1', { defaultValue: "Partner with us for customized translation solutions." })}</p>
            <p>{t('corporatePartnershipsDesc2', { defaultValue: "Contact us to learn more about our corporate packages." })}</p>
            <p>{t('corporatePartnershipsDesc3', { defaultValue: "Email us at translator@youtranslate.me for more information." })}</p>
          </div>
          <div className="terms-container" id="terms">
            <h2>{t('termsConditions', { defaultValue: "Terms & Conditions" })}</h2>
            <p><strong>{t('lastUpdated', { defaultValue: "Last Updated:" })}</strong> {t('lastUpdatedDate', { defaultValue: "May 1, 2024" })}</p>
            <p>{t('termsIntro', { defaultValue: "These terms and conditions outline the rules and regulations for the use of YouTranslate's Website, located at youtranslate.me." })}</p>
            <h3>{t('introduction', { defaultValue: "Introduction" })}</h3>
            <ul>
              <li>{t('introductionItem1', { defaultValue: "By accessing this website we assume you accept these terms and conditions. Do not continue to use YouTranslate if you do not agree to all of the terms and conditions stated on this page." })}</li>
              <li>{t('introductionItem2', { defaultValue: "The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: 'Client', 'You' and 'Your' refers to you, the person log on this website and compliant to the Company’s terms and conditions. 'The Company', 'Ourselves', 'We', 'Our' and 'Us', refers to our Company. 'Party', 'Parties', or 'Us', refers to both the Client and ourselves." })}</li>
            </ul>
            <h3>{t('copyrightAndPermissions', { defaultValue: "Copyright & Permissions" })}</h3>
            <ul>
              <li>{t('copyrightItem1', { defaultValue: "YouTranslate uses third-party content (e.g., videos) for translation. It is your responsibility to ensure that you have the right to use the content for translation and that your use complies with any applicable laws or copyright policies." })}</li>
            </ul>
            <h3>{t('serviceDescription', { defaultValue: "Service Description" })}</h3>
            <ul>
              <li>{t('serviceDescItem1', { defaultValue: "YouTranslate provides automated translations of video and audio content to various languages, intended to assist in making content accessible to non-native speakers." })}</li>
              <li>{t('serviceDescItem2', { defaultValue: "While we strive for accuracy, the translations provided by YouTranslate may not always be perfect." })}</li>
            </ul>
            <h3>{t('userObligations', { defaultValue: "User Obligations" })}</h3>
            <ul>
              <li>{t('userObligationsItem1', { defaultValue: "You agree to use YouTranslate's services only for lawful purposes and in a way that does not infringe the rights of, restrict or inhibit anyone else's use and enjoyment of the website." })}</li>
              <li>{t('userObligationsItem2', { defaultValue: "Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offense." })}</li>
            </ul>
            <h3>{t('limitationOfLiability', { defaultValue: "Limitation of Liability" })}</h3>
            <ul>
              <li>{t('limitationItem1', { defaultValue: "YouTranslate and its creators will not be liable for any inaccuracies in the translations or any damages resulting from the use of the translated content." })}</li>
              <li>{t('limitationItem2', { defaultValue: "We are also not responsible for the content of external sites that are linked to or from YouTranslate." })}</li>
            </ul>
            <h3>{t('amendmentsToTerms', { defaultValue: "Amendments to Terms" })}</h3>
            <ul>
              <li>{t('amendmentsItem1', { defaultValue: "We reserve the right to amend these terms from time to time. Every time you wish to use our site, please check these terms to ensure you understand the terms that apply at that time." })}</li>
            </ul>
          </div>
          <div className="terms-container" id="privacy">
            <h2>{t('privacyPolicyTitle', { defaultValue: "Privacy Policy" })}</h2>
            <p>{t('privacyPolicyIntro', { defaultValue: "Your privacy is important to us. It is YouTranslate's policy to respect your privacy and comply with any applicable law and regulation regarding any personal information we may collect about you, including across our website, youtranslate.me, and other sites we own and operate. This includes compliance with the General Data Protection Regulation (GDPR) and the California Privacy Rights Act (CPRA)." })}</p>
            <h3>{t('informationWeCollectTitle', { defaultValue: "Information We Collect" })}</h3>
            <p>{t('informationWeCollectDesc', { defaultValue: "Information we collect includes both information you knowingly and actively provide us when using or participating in any of our services and promotions, and any information automatically sent by your devices in the course of accessing our products and services. This includes, but is not limited to, your name, email address, social media profiles, date of birth, phone/mobile number, and home/mailing address." })}</p>
            <h3>{t('logDataTitle', { defaultValue: "Log Data" })}</h3>
            <p>{t('logDataDesc', { defaultValue: "When you visit our website, our servers may automatically log the standard data provided by your web browser. This data may include your device’s Internet Protocol (IP) address, your browser type and version, the pages you visit, the time and date of your visit, the time spent on each page, and other technical details." })}</p>
            <h3>{t('personalInformationTitle', { defaultValue: "Personal Information" })}</h3>
            <p>{t('personalInformationDesc', { defaultValue: "We may ask for personal information which may include one or more of the following: name, email address, social media profiles, date of birth, phone/mobile number, and home/mailing address. This information is collected to provide and improve our services, communicate with you, and ensure the security of our website." })}</p>
            <h3>{t('securityOfYourInformationTitle', { defaultValue: "Security of Your Information" })}</h3>
            <p>{t('securityOfYourInformationDesc', { defaultValue: "We secure the information you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use, or disclosure. We maintain reasonable administrative, technical, and physical safeguards in an effort to protect against unauthorized access, use, modification, and disclosure of personal information in our control and custody." })}</p>
            <h3>{t('childrensPrivacyTitle', { defaultValue: "Children’s Privacy" })}</h3>
            <p>{t('childrensPrivacyDesc', { defaultValue: "We do not knowingly collect personally identifiable information from children under the age of 13. If you are under the age of 13, you must ask your parent or guardian for permission to use this website. If we become aware that we have collected personal information from a child under the age of 13 without verification of parental consent, we will take steps to remove that information from our servers." })}</p>
            <h3>{t('cookiePolicyTitle', { defaultValue: "Cookie Policy" })}</h3>
            <p>{t('cookiePolicyDesc', { defaultValue: "Our website uses 'cookies' to help personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you and can only be read by a web server in the domain that issued the cookie to you. We use cookies to understand and save your preferences for future visits, keep track of advertisements, and compile aggregate data about site traffic and site interactions." })}</p>
            <h3>{t('amendmentsToPrivacyPolicyTitle', { defaultValue: "Amendments to This Policy" })}</h3>
            <p>{t('amendmentsToPrivacyPolicyDesc', { defaultValue: "We may update our Privacy Policy from time to time, and we will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Continued use of our website after any modifications to the Privacy Policy will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy." })}</p>
            <h3>{t('yourRightsTitle', { defaultValue: "Your Rights" })}</h3>
            <p>{t('yourRightsDesc', { defaultValue: "You have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please submit a request to the contact information below. Additionally, under GDPR and CPRA, you have the right to object to the processing of your personal data, request data portability, and lodge a complaint with a supervisory authority." })}</p>
            <h3>{t('contactPrivacyTitle', { defaultValue: "Contact Us" })}</h3>
            <p>{t('contactPrivacyDesc', { defaultValue: "If you have any questions or concerns about our privacy policies, please contact us at translator@youtranslate.me. For GDPR and CPRA inquiries or requests, please include 'GDPR' or 'CPRA' in the subject line of your email." })}</p>
          </div>
        </div>
      </div>

      <footer className="footer">
        <div className="footer-content">
          <div className="footer-links">
            <a href="#pricing" className="about-link">{t('navPricing', { defaultValue: "Pricing" })}</a>
            <a href="#about" className="about-link">{t('navAbout', { defaultValue: "About" })}</a>
            <a href="#terms" className="about-link">{t('navTerms', { defaultValue: "Terms" })}</a>
            <a href="#privacy" className="about-link">{t('navPrivacy', { defaultValue: "Privacy" })}</a>
          </div>
        </div>
        © 2024 YouTranslate. All rights reserved.
      </footer>
    </div>
  );
}

export default App;
